import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "btn-toolbar" }
const _hoisted_3 = {
  key: 0,
  class: "table-settings mb-4"
}
const _hoisted_4 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_5 = {
  key: 0,
  class: ""
}
const _hoisted_6 = {
  key: 1,
  class: ""
}
const _hoisted_7 = {
  key: 2,
  class: ""
}
const _hoisted_8 = { class: "" }
const _hoisted_9 = {
  key: 1,
  class: "row"
}
const _hoisted_10 = {
  key: 0,
  class: "col-12 pb-5"
}
const _hoisted_11 = { class: "text-right mx-2" }
const _hoisted_12 = { class: "d-lg-flex flex-row justify-content-end align-items-center py-1" }
const _hoisted_13 = { class: "mr-3" }
const _hoisted_14 = { class: "card card-body border-light shadow-sm" }
const _hoisted_15 = { class: "text-center" }
const _hoisted_16 = { class: "fw-bolder" }
const _hoisted_17 = { class: "row justify-content-center text-center py-3" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "col-6" }
const _hoisted_20 = { class: "row text-center py-3" }
const _hoisted_21 = { class: "col-12 col-md-4" }
const _hoisted_22 = { class: "col-12 col-md-4" }
const _hoisted_23 = { class: "col-12 col-md-4" }
const _hoisted_24 = { class: "row text-center py-3" }
const _hoisted_25 = { class: "col-12 col-md-6" }
const _hoisted_26 = { class: "col-12 col-md-6" }
const _hoisted_27 = { class: "col-12 pb-5" }
const _hoisted_28 = { class: "card card-body border-light shadow-sm" }
const _hoisted_29 = { class: "row text-center py-3" }
const _hoisted_30 = { class: "col-4" }
const _hoisted_31 = { class: "col-4" }
const _hoisted_32 = { class: "col-4" }
const _hoisted_33 = { class: "col-12 pb-5" }
const _hoisted_34 = { class: "card card-body border-light shadow-sm" }
const _hoisted_35 = { class: "row justify-content-center text-center py-3" }
const _hoisted_36 = { class: "col-6" }
const _hoisted_37 = { class: "col-6" }
const _hoisted_38 = { class: "row mt-4 gy-4 border justify-content-center text-center py-3" }
const _hoisted_39 = { class: "text-gray" }
const _hoisted_40 = { class: "col-12 mt-5 mt-md-0" }
const _hoisted_41 = { class: "card card-body border-light shadow-sm" }
const _hoisted_42 = { class: "card card-body border-light shadow-sm table-wrapper table-responsive pt-0" }
const _hoisted_43 = { class: "table table-hover" }
const _hoisted_44 = {
  key: 2,
  class: "row"
}
const _hoisted_45 = {
  key: 0,
  class: "card-withdrawal-detail-response"
}
const _hoisted_46 = {
  class: "",
  style: {"width":"24rem"}
}
const _hoisted_47 = { class: "card-body d-flex justify-content-center align-items-center" }
const _hoisted_48 = { class: "" }
const _hoisted_49 = { key: 0 }
const _hoisted_50 = { key: 1 }
const _hoisted_51 = { class: "mb-3 response-list" }
const _hoisted_52 = {
  key: 0,
  class: ""
}
const _hoisted_53 = { class: "my-2" }
const _hoisted_54 = { class: "d-flex justify-content-between" }
const _hoisted_55 = { key: 1 }
const _hoisted_56 = ["onClick"]
const _hoisted_57 = { class: "d-flex align-items-center" }
const _hoisted_58 = {
  key: 0,
  class: "text-underline text-info"
}
const _hoisted_59 = {
  key: 1,
  class: "text-underline text-info"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!
  const _component_SecondaryModal = _resolveComponent("SecondaryModal")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _cache[10] || (_cache[10] = _createElementVNode("div", { class: "d-block" }, [
            _createElementVNode("h2", { class: "h4" }, "Withdrawal Transaction Details")
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, "Reload")
          ])
        ]),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                (['SUBMITTED', 'PROCESSING'].includes(_ctx.data.status))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-sm btn-success",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to approve this transaction?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to approve this transaction? This is an irreversible action',
                      _ctx.ApproveTransaction
                    );
                  }
                )
              })
                      }, "Approve Transaction")
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.data.status === 'FAILED')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-sm btn-secondary text-nowrap",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to retry this transaction?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to retry transaction? This is an irreversible action',
                      _ctx.ProcessTransaction
                    );
                  }
                )
              })
                      }, "Retry Transaction")
                    ]))
                  : _createCommentVNode("", true),
                (['SUBMITTED', 'FAILED', 'PROCESSING'].includes(_ctx.data.status))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("button", {
                        type: "button",
                        class: "btn btn-sm btn-dark text-nowrap",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to manually certify this transaction?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to set transaction manually? This is an irreversible action',
                      _ctx.ManualTransaction
                    );
                  }
                )
              })
                      }, "Manual Success")
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_8, [
                  (['SUBMITTED', 'FAILED', 'PROCESSING'].includes(_ctx.data.status))
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        class: "btn btn-sm btn-danger text-nowrap",
                        onClick: _cache[4] || (_cache[4] = ($event: any) => {
                _ctx.RequestConfirmation(
                  'Do you really want to decline this withdrawal?',
                  () => {
                    _ctx.RequestConfirmation(
                      'Sure to decline this transaction? This is an irreversible action',
                      () => {
                        _ctx.sureConfirmDecline = true;
                      }
                    );
                  }
                )
              })
                      }, "Decline Withdrawal"))
                    : _createCommentVNode("", true)
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        (_ctx.data)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              (_ctx.userBalance)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _cache[11] || (_cache[11] = _createElementVNode("h4", { class: "text-gray" }, "Total Balance", -1)),
                      _createElementVNode("div", _hoisted_12, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userBalance, (bal) => {
                          return (_openBlock(), _createElementBlock("h5", {
                            class: "fw-bolder text-info border border-r ms-3 p-3",
                            key: bal
                          }, [
                            _createElementVNode("span", _hoisted_13, _toDisplayString(bal.Currency.code), 1),
                            _createTextVNode(" " + _toDisplayString(bal.balance), 1)
                          ]))
                        }), 128))
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _createElementVNode("div", _hoisted_15, [
                        _cache[12] || (_cache[12] = _createElementVNode("h4", { class: "text-gray" }, "Created At", -1)),
                        _createElementVNode("h5", _hoisted_16, _toDisplayString(_ctx.data.created_at), 1)
                      ]),
                      _createElementVNode("div", _hoisted_17, [
                        _createElementVNode("div", _hoisted_18, [
                          _cache[13] || (_cache[13] = _createElementVNode("h4", { class: "text-gray" }, "Status", -1)),
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.status), 1)
                        ]),
                        _createElementVNode("div", _hoisted_19, [
                          _cache[14] || (_cache[14] = _createElementVNode("h4", { class: "text-gray" }, "WalletId", -1)),
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.fiat_wallet_id), 1)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("div", _hoisted_21, [
                          _cache[15] || (_cache[15] = _createElementVNode("h4", { class: "text-gray" }, "UserId", -1)),
                          _createVNode(_component_router_link, {
                            to: `/users/${_ctx.data.User.id}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.id), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _createElementVNode("div", _hoisted_22, [
                          _cache[16] || (_cache[16] = _createElementVNode("h4", { class: "text-gray" }, "Email", -1)),
                          _createVNode(_component_router_link, {
                            to: `/users/${_ctx.data.User.id}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.email), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ]),
                        _createElementVNode("div", _hoisted_23, [
                          _cache[17] || (_cache[17] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                          _createVNode(_component_router_link, {
                            to: `/users/${_ctx.data.User.id}`
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("h6", null, _toDisplayString(_ctx.data.User.Profile.first_name) + " " + _toDisplayString(_ctx.data.User.Profile.last_name), 1)
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("div", _hoisted_25, [
                          _cache[18] || (_cache[18] = _createElementVNode("h4", { class: "text-gray" }, "Comment", -1)),
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.description ? _ctx.data.description : "No Description"), 1)
                        ]),
                        _createElementVNode("div", _hoisted_26, [
                          _cache[19] || (_cache[19] = _createElementVNode("h4", { class: "text-gray" }, "Remark", -1)),
                          _createElementVNode("h6", null, _toDisplayString(_ctx.data.remark ? _ctx.data.remark : "No Remark"), 1)
                        ])
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("div", _hoisted_28, [
                  _cache[23] || (_cache[23] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Amount Information")
                  ], -1)),
                  _createElementVNode("div", _hoisted_29, [
                    _createElementVNode("div", _hoisted_30, [
                      _cache[20] || (_cache[20] = _createElementVNode("h4", { class: "text-gray" }, "Code", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.code), 1)
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[21] || (_cache[21] = _createElementVNode("h4", { class: "text-gray" }, "Name", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.FiatCurrency.name), 1)
                    ]),
                    _createElementVNode("div", _hoisted_32, [
                      _cache[22] || (_cache[22] = _createElementVNode("h4", { class: "text-gray" }, "Amount", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.amount), 1)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _cache[26] || (_cache[26] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Credit Information")
                  ], -1)),
                  _createElementVNode("div", _hoisted_35, [
                    _createElementVNode("div", _hoisted_36, [
                      _cache[24] || (_cache[24] = _createElementVNode("h4", { class: "text-gray" }, "Account Number", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.BankAccount.account_number), 1)
                    ]),
                    _createElementVNode("div", _hoisted_37, [
                      _cache[25] || (_cache[25] = _createElementVNode("h4", { class: "text-gray" }, "Bank Code", -1)),
                      _createElementVNode("h6", null, _toDisplayString(_ctx.data.BankAccount.bank_code), 1)
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_38, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.BankAccount.details, (value, key) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "col-4",
                        key: key
                      }, [
                        _createElementVNode("h4", _hoisted_39, _toDisplayString(key), 1),
                        _createElementVNode("h6", null, _toDisplayString(value), 1)
                      ]))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_40, [
                _createElementVNode("div", _hoisted_41, [
                  _cache[28] || (_cache[28] = _createElementVNode("div", { class: "text-center" }, [
                    _createElementVNode("h6", { class: "text-gray" }, "Payment Attempts")
                  ], -1)),
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("table", _hoisted_43, [
                      _cache[27] || (_cache[27] = _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", null, "#"),
                          _createElementVNode("th", null, "Reference"),
                          _createElementVNode("th", null, "FLW ID"),
                          _createElementVNode("th", null, "Status"),
                          _createElementVNode("th", null, "Date")
                        ])
                      ], -1)),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.PaymentOutwards, (dt, index) => {
                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", null, _toDisplayString(dt.id), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.reference), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.flw_tx_id), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.status), 1),
                            _createElementVNode("td", null, _toDisplayString(dt.created_at), 1)
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_44, _cache[29] || (_cache[29] = [
              _createElementVNode("div", { class: "col-12" }, [
                _createElementVNode("h1", { class: "text-danger text-center" }, "No Data!")
              ], -1)
            ])))
      ]),
      (_ctx.sureConfirmDecline)
        ? (_openBlock(), _createElementBlock("div", _hoisted_45, [
            _createVNode(_component_SecondaryModal, {
              modalActive: _ctx.sureConfirmDecline,
              onClose: _cache[9] || (_cache[9] = ($event: any) => (_ctx.sureConfirmDecline = false))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_46, [
                  _createElementVNode("div", _hoisted_47, [
                    _createVNode(_component_DefaultLoader, { loading: _ctx.loading }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_48, [
                          _cache[30] || (_cache[30] = _createElementVNode("h5", { class: "card-title my-3 text-center" }, "Reason for Decline", -1)),
                          _createElementVNode("div", null, [
                            (_ctx.customComment)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_49, "Custom Response"))
                              : (_openBlock(), _createElementBlock("span", _hoisted_50, "Automated Response"))
                          ]),
                          _createElementVNode("div", _hoisted_51, [
                            (_ctx.customComment)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_52, [
                                  _createElementVNode("div", _hoisted_53, [
                                    _withDirectives(_createElementVNode("textarea", {
                                      class: "form-control",
                                      placeholder: "Leave a comment here",
                                      id: "remark",
                                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.declineComment) = $event)),
                                      style: {"height":"100px","min-width":"100%"},
                                      required: ""
                                    }, null, 512), [
                                      [_vModelText, _ctx.declineComment]
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_54, [
                                    _createElementVNode("button", {
                                      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.DeclineTransaction(_ctx.declineComment))),
                                      type: "button",
                                      class: "btn btn-primary"
                                    }, "Submit")
                                  ])
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_55, [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.automatedResponses, (remark) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                      class: "d-flex flex-column my-1",
                                      key: remark.id
                                    }, [
                                      _createElementVNode("div", {
                                        class: "decline-response text-white btn p-1",
                                        onClick: ($event: any) => (_ctx.DeclineTransaction(remark.response))
                                      }, _toDisplayString(remark.response), 9, _hoisted_56)
                                    ]))
                                  }), 128))
                                ]))
                          ]),
                          _createElementVNode("div", _hoisted_57, [
                            _createElementVNode("button", {
                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.sureConfirmDecline = false)),
                              type: "button",
                              class: "btn btn-light me-auto"
                            }, "Cancel"),
                            _createElementVNode("h6", {
                              class: "cursor-pointer mx-4",
                              onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.customComment = !_ctx.customComment), ["prevent"]))
                            }, [
                              (!_ctx.customComment)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_58, "Enter a custom response"))
                                : (_openBlock(), _createElementBlock("span", _hoisted_59, "Use Automated response"))
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["modalActive"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["loading"]))
}